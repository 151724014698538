<template>
    <div>
        <van-cell-group title="通用">
            <van-cell title="整改记录" is-link size="large" icon="records" to="/ins/rectify/record" />
            <van-cell title="巡检记录" is-link size="large" icon="todo-list-o" to="/ins/list" />
            <!-- <van-cell title="新增巡检" is-link size="large" icon="plus" to="/ins/new" /> -->
            <van-cell title="新增巡检" is-link size="large" icon="plus" @click="showShare = true" />
            <van-share-sheet v-model="showShare" title="请选择类型" :options="options" @select="onSelect" />
        </van-cell-group>
    </div>
</template>

<script>
    import { Toast } from "vant";
    export default {
        name: "index",
        components: {},
        data() {
            return {
                showShare: false,
                options: [
                    [
                        { name: '微信', icon: 'wechat' },
                        { name: '朋友圈', icon: 'wechat-moments' },
                        { name: '微博', icon: 'weibo' },
                        { name: 'QQ', icon: 'qq' }
                    ]
                ],
                typeList: [],
                itemList: []
            }
        },
        created() {
            this.getTypes();
        },
        methods: {
            getTypes() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetTypes",
                    completed: function (res) {
                        self.typeList = res.DATA
                        self.options = res.DATA.map(x => ({ id: x.ID, name: x.NAME, icon: x.ICON_KV.Value }))
                        // console.log(self.options)
                    },
                });
            },
            onSelect(option) {
                this.itemList = this.typeList.find(x => x.NAME == option.name).ITEM_LIST
                if (this.itemList.length > 0) {
                    this.$router.push({
                        path: "/ins/new",
                        query: { tid: option.id, tname: option.name, items: JSON.stringify(this.itemList) },
                    });
                } else {
                    Toast.fail(`${option.name} 没有项目`);
                }
                this.showShare = false;
            },
        },
    }
</script>

<style scoped></style>